/** @jsx jsx */
import { jsx, Styled, Textarea } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form, Field } from 'formik';
import { Container } from '../components';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import { withFormik } from 'formik';
import Link from '../components/Link';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import DropzoneField from '../components/DropzoneField';
import { yupMaxEmailFileSizeText } from '../utils';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import * as analytics from '../utils/analytics';

const feedbackSchema = translate =>
  yup.object().shape({
    type: yup
      .string()
      .oneOf(['travel', 'parcel'], translate('feedback.message.invalidType'))
      .required(translate('feedback.message.requiredField')),
    name: yup.string(),
    email: yup
      .string()
      .email(translate('feedback.message.invalidEmail'))
      .when('reply', {
        is: translate('feedback.replies.yes'),
        then: yup.string().required(translate('feedback.message.requiredField')),
      }),
    phone: yup.string(),
    streetAddress: yup.string(),
    post: yup.string(),
    trackingNumber: yup.string(),
    feedback: yup.string().required(translate('feedback.message.requiredField')),
    attachment: yup
      .array()
      .of(yup.mixed())
      .max(2, translate('feedback.message.tooManyFiles', { files: 2 }))
      .ensure(),
    /*.test('tooBig', translate('feedback.message.sizeLimit', { size: '10MB' }), yupMaxEmailFileSizeText)*/ recaptcha: yup
      .string()
      .required(),
  });

const RemarkBox = ({ children }) => (
  <div
    sx={{
      bg: 'paleYellow',
      py: 3,
      px: 3,
      borderRadius: 1,
      mt: 2,
      mb: 3,
      lineHeight: 'normal',
    }}
  >
    {children}
  </div>
);

const FeedbackForm = ({ translate, handleSubmit, isSubmitting, setFieldValue, errors, values, nocaptcha }) => {
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <RadioFieldGroup id="type" error={errors.type} label={translate('feedback.type')}>
        <Field component={RadioField} name="type" id="travel" label={translate('feedback.types.travel')} />
        <Field component={RadioField} name="type" id="parcel" label={translate('feedback.types.parcel')} />
      </RadioFieldGroup>
      {values.type === 'parcel' && (
        <RemarkBox>
          {translate('feedback.parcelNote.text1')} <Link to="/tracking">{translate('feedback.parcelNote.link1')}</Link>{' '}
          {translate('feedback.parcelNote.text2')}{' '}
          <Link to="/asiakaspalvelu?">{translate('feedback.parcelNote.link2')}</Link>
        </RemarkBox>
      )}
      {/* values.type === 'travel' && (
        <RemarkBox>
          {translate('feedback.travelNote')}
        </RemarkBox>
      ) */}
      <FormField name="name" label={translate('feedback.name')} />
      <FormField name="email" label={translate('feedback.email')} />
      <FormField name="phone" label={translate('feedback.phone')} />
      {/*
      <FormField
        hidden={values.type !== 'parcel'}
        name="streetAddress"
        label={translate('feedback.streetAddress')}
      />
      <FormField
        hidden={values.type !== 'parcel'}
        name="post"
        label={translate('feedback.post')}
      />
      */}
      <FormField hidden={values.type !== 'parcel'} name="trackingNumber" label={translate('feedback.trackingNumber')} />
      <FormField name="feedback" as={Textarea} label={translate('feedback.feedback')} rows={4} />
      <DropzoneField
        name="attachment"
        label={translate('feedback.attachment')}
        placeholder={translate('feedback.dropzoneText')}
        clearButtonText={translate('feedback.clear')}
        zoneActiveText={translate('feedback.dropzoneActiveText')}
        newLabel={translate('feedback.feedbackAttachmentSize', { maxSize: 5 })}
      />
      <RecaptchaButton
        buttonText="feedback.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
      />
    </Form>
  );
};

const FeedbackFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      type: vo.type || '',
      name: vo.name || '',
      email: vo.email || '',
      phone: vo.phone || '',
      streetAddress: vo.streetAddress || '',
      post: vo.post || '',
      trackingNumber: vo.trackingNumber || '',
      reply: vo.reply || '',
      feedback: vo.feedback || '',
      attachment: vo.attachment || [],
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return feedbackSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'FeedbackInputForm',
})(FeedbackForm);

const FeedbackPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendFeedback({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: { message: translate('feedback.message.success') },
          })
        );
        //dispatch(showNotification('feedback.message.success', SEVERITY.INFO));
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('feedback.message.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout title={translate('feedback.title')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('feedback.title')}</Styled.h1>
        <p sx={{ py: 2 }}>{translate('feedback.text')}</p>
        <p>
          {translate('feedback.text2')}{' '}
          <Link to="/asiakaspalvelu?" sx={{ textDecoration: 'underline' }}>
            {translate('feedback.link')}
          </Link>{' '}
          {translate('feedback.text3')}
        </p>

        <FeedbackFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default FeedbackPage;
